const videoFullBarba = () => {
  if (window.innerWidth < 993) {
    const video = document.querySelector('.video-pick-card video')

    if (video) {
      video.style.display = 'none'
      video.remove()
    }
  }
}

export { videoFullBarba }
