class Header {
  constructor () {
    this.initHeader()
    this.initMenu()
  }

  initHeader () {
    // Menu button event listener
    const mobileMenuToogler = document.getElementById('header__menu-toggle')
    if (mobileMenuToogler) {
      mobileMenuToogler.addEventListener('change', blockScroll)
    }

    function blockScroll (e) {
      const style = document.body.style
      // style.overflowY = e.target.checked ? 'hidden' : 'auto'
      style.overflow = e.target.checked ? 'hidden' : 'auto'
    }
  }

  initMenu () {
    const menu = document.getElementsByClassName('header__menu-inner')[0]
    menu.addEventListener('click', function (e) {
      try {
        const li = e.target.parentElement
        const enable = !li.classList.contains('isOpen')
        if (li && li.classList.contains('menu-item-has-children')) {
          for (let i = 0; i < li.parentNode.children.length; i++) {
            li.parentNode.children[i].classList.remove('isOpen')
          }
          if (enable) {
            li.classList.add('isOpen')
          }
          e.preventDefault()
          e.stopPropagation()
        } else {
          const toggler = document.getElementById('header__menu-toggle')
          if (toggler.checked) {
            toggler.click()
          }
        }
      } catch {}
    })
  }
}

export default Header
