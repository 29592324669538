const addOwlCarouselHeaderScriptAndStart = () => {
  if (!document.querySelector('script[src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"]')) {
    const script2 = document.createElement('script')
    script2.type = 'text/javascript'
    script2.src = 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js'

    script2.onload = () => {
      loadOwl()
    }

    document.head.appendChild(script2)
  } else loadOwl()

  if (!document.querySelector('link[href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css"]')) {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css'
    document.head.appendChild(link)
  }
}

function loadOwl () {
  (($) => {
    if ($('.custom-carousel')) {
      $('.custom-carousel').owlCarousel({
        margin: 20,
        items: 4,
        loop: false,
        autoplay: false,
        autoWidth: true,
        nav: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 2
          },
          900: {
            items: 3
          },
          1300: {
            items: 4
          }
        }
      })
    }
  })(window.jQuery)
}
export { addOwlCarouselHeaderScriptAndStart }
