class Accordion {
  constructor () {
    this.init()
  }

  init () {
    const acc = document.getElementsByClassName('accordion__header')
    let i

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('accordion__header--active')
        const content = this.nextElementSibling
        if (content.style.maxHeight) {
          content.style.maxHeight = null
        } else {
          content.style.maxHeight = content.scrollHeight + 'px'
        }
      })
    }
  }
}

export default Accordion
