import Interceptor from './interceptor'
class ContactForm {
  constructor () {
    this.submitValidateForm = this.submitValidateForm.bind(this)
    this.init()
  }

  init () {
    const fillableTypes = ['text', 'email', 'select-one', 'textarea', 'checkbox', 'hidden', 'file']
    const wrapper = document.getElementsByClassName('js-form')[0]
    if (!wrapper) return
    this.firstForm = wrapper.getElementsByTagName('form')[0]
    if (!this.firstForm) return

    this.firstFormSendButton = document.getElementsByClassName('contact-button')[0]
    this.closeNotificationButton = document.getElementById('close-notification')
    this.fieldNames = [...this.firstForm.elements]
      .filter(e => fillableTypes.includes(e.type))
      .map(e => e.name)
    this.fields = this.fieldNames.map(f => document.getElementsByName(f)[0])
    this.newForm()
  }

  newForm () {
    if (window.recaptchaPublicKey) {
      // Load Re-captcha V3
      var script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=' + window.recaptchaPublicKey)
      document.getElementsByTagName('head')[0].appendChild(script)
    }

    // Prevent form submit
    this.firstForm.addEventListener('submit', this.submitValidateForm)

    // Enable button
    if (document.getElementById('privacy_policy')) {
      const provacyPolicy = document.getElementById('privacy_policy')
      provacyPolicy.addEventListener('change', () => {
        provacyPolicy.checked ? this.firstFormSendButton.disabled = false : this.firstFormSendButton.disabled = true
      })
    } else {
      this.firstFormSendButton.disabled = false
    }
    // Close notificacion banner
    this.closeNotificationButton.addEventListener('click', this.closeNotificationBanner)

    // test show flags
    document.getElementById('selectCity').addEventListener('change', function (b) {
      var combo = document.getElementById('selectCity')
      var ban = combo.options[combo.selectedIndex].value
      var selected = combo.options[combo.selectedIndex].text
      document.getElementById('seleccionado2').innerHTML = selected
      const miPais = `<div class='todojunto'><i class='${ban} flag'></i></div>`
      document.querySelector('.sitio_foto').innerHTML = miPais
      return miPais
    })
  }

  submitValidateForm (evt) {
    evt.preventDefault()

    // Get the form parameters
    const successMessage = document.getElementsByName('success_message')[0]
    const errorMessage = document.getElementsByName('error_message')[0]
    const notificationsBanner = document.getElementById('notification-banner')
    const notificationMessage = document.getElementsByClassName('notification-text')[0]
    const fields = this.fields
    const fieldNames = this.fieldNames
    const form = evt.target
    const submitBtn = this.firstFormSendButton
    const variant = document.getElementsByName('variant')[0].value
    // Prevent double-submission
    if (submitBtn.disabled) {
      return
    }

    // Disable and animate the submit button
    submitBtn.disabled = true
    submitBtn.classList.add('validating')

    const submit = (token) => {
    // Ajax form submit
      const xmlhttp = window.ajaxReq()
      let url = encodeURI(scripts_ajax_variables.ajax_url) // eslint-disable-line

      // prepare data from form, not from tiket
      const values = fields
        .filter(f => f.type !== 'checkbox' || f.checked)
        .map((f, i) => `${encodeURIComponent(fieldNames[i])}=${encodeURIComponent(f.value)}`).join('&')
      const params = values + '&token=' + token

      // ticket data binary
      const formData = new window.FormData(form)
      formData.append('token', token)

      xmlhttp.open('POST', url, true)// set true for async, false for sync request
      if (variant !== 'ticket') xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')

      if (variant !== 'ticket') xmlhttp.send(params)
      else xmlhttp.send(formData)

      // or null, if no parameters are passed
      // Validation
      xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
          try {
            var data = JSON.parse(xmlhttp.responseText)
            // Hide previous errors
            fields.forEach(f => f.classList.remove('show-error'))

            if (data.validation) {
              // Success notification message
              notificationMessage.innerHTML = successMessage.value
              // inicialiced class interceptor

              this.inter = new Interceptor()
              this.resa = this.inter.initfilter(values)
              // Clear the form
              form.reset()

              if (document.getElementById('listOfselectedFiles')) {
                document.getElementById('listOfselectedFiles').innerHTML = ''
              }
            } else {
              // Error notification message
              notificationMessage.innerHTML = errorMessage.value

              // Mark errors in the form
              fields.forEach((f, i) => {
                if (data[fieldNames[i]]) {
                  f.classList.add('show-error')
                }
              })
            }
          } catch (error) {
            notificationMessage.innerHTML = error.message
          }
        }

        // Enable and stop the submit button animation
        notificationsBanner.classList.add('active')
        submitBtn.disabled = false
        submitBtn.classList.remove('validating')
      }
    } // submit

    if (window.recaptchaPublicKey) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(window.recaptchaPublicKey, { action: variant }).then(submit)
      })
    } else {
      submit('')
    }
  } // submitValidateForm

  closeNotificationBanner (evt) {
    evt.preventDefault()
    evt.srcElement.parentNode.classList.remove('active')
  }
}
export default ContactForm
