class Filterfromselect {
  constructor (param) {
    this.param = param
  }
  // ======================================== //
  // ====  FILTERING SELECT TO CRM  ======== //
  // ======================================== //

  getdatafromSlectInput () {
    var selector = document.getElementById('industry')
    var selectorvalues = selector.options
    var itemsOfSelect = []
    for (let acumulator = 0; acumulator < selectorvalues.length; acumulator++) {
      itemsOfSelect.push(selectorvalues[acumulator].value)
    }
    var actualvalue

    switch (this.param) {
      case itemsOfSelect[1]:
        actualvalue = 1
        break
      case itemsOfSelect[2]:
        actualvalue = 3
        break
      case itemsOfSelect[3]:
        actualvalue = 5
        break
      case itemsOfSelect[4]:
        actualvalue = 6
        break
      case itemsOfSelect[5]:
        actualvalue = 7
        break
      case itemsOfSelect[6]:
        actualvalue = 8
        break
      case itemsOfSelect[7]:
        actualvalue = 10
        break
      case itemsOfSelect[8]:
        actualvalue = 11
        break
      case itemsOfSelect[9]:
        actualvalue = 12
        break
      case itemsOfSelect[10]:
        actualvalue = 13
        break
      case itemsOfSelect[11]:
        actualvalue = 18
        break
      case itemsOfSelect[12]:
        actualvalue = 19
        break
      case itemsOfSelect[13]:
        actualvalue = 20
        break
      case itemsOfSelect[14]:
        actualvalue = 21
        break
      case itemsOfSelect[15]:
        actualvalue = 22
        break
      case itemsOfSelect[16]:
        actualvalue = 23
        break
    }
    return actualvalue
  }
  // ======================================== //
  // ====  FILTERING SELECT TO CRM  ======== //
  // ====================================== //
}

export default Filterfromselect
