export const iconsHero = () => {
  const VIDEOCONTAINERS = [...document.querySelectorAll('.autoplay-video-container')]
  // Habilitar el video para que se reproduzca si hace scroll

  let userInteracted = false
  document.addEventListener('scroll', () => { userInteracted = true })

  VIDEOCONTAINERS.forEach((containerVid) => {
    const VIDEOICON = containerVid.querySelector('.features__feature-image > .video-icon')

    containerVid.addEventListener('mouseenter', function () {
      if (userInteracted && VIDEOICON && VIDEOICON.paused) VIDEOICON.play().catch((error) => { console.error('Error attempting to play the video:', error) })
    })

    containerVid.addEventListener('mouseleave', function () {
      if (userInteracted && VIDEOICON) VIDEOICON.pause()
    })
  })
}

export default iconsHero
