const cargaInicialHeroMobile = () => {
  let grados = 0

  const frontImage = document.querySelector('.front img')
  const backImage = document.querySelector('.back img')
  const flipper = document.querySelector('.flipper')

  // SOMBRA IMG

  if (backImage) backImage.style = 'filter: drop-shadow(10px 12px 30px rgba(68, 64, 64, 0.2))'
  if (frontImage) frontImage.style = 'filter: drop-shadow(10px 12px 30px rgba(68, 64, 64, 0.2))'

  let isFlipped = false
  let currentIndex = 0
  const mainImg = [
    '/wp-content/themes/kuady/images/web-hero-imgs/mobile-hero/en/card-screen.png',
    '/wp-content/themes/kuady/images/web-hero-imgs/mobile-hero/en/pay-screen.png',
    '/wp-content/themes/kuady/images/web-hero-imgs/mobile-hero/en/T-shirt.png'
  ]

  // Establecer la primera imagen al inicio
  if (frontImage) frontImage.src = mainImg[0]
  if (backImage) backImage.src = mainImg[1]

  setInterval(() => {
    if (isFlipped) {
      grados = grados + 180
      if (flipper) flipper.style.transform = `rotateY(${grados}deg)`

      currentIndex = (currentIndex + 1) % mainImg.length
      if (frontImage) frontImage.src = mainImg[currentIndex]
    } else {
      grados = grados + 180
      if (flipper) flipper.style.transform = `rotateY(${grados}deg)`

      currentIndex = (currentIndex + 1) % mainImg.length
      if (backImage) backImage.src = mainImg[currentIndex]
    }

    isFlipped = !isFlipped

    // Mostrar las imágenes cuando haya terminado la transición
    setTimeout(() => {
      if (frontImage) frontImage.style.visibility = 'visible'
      if (backImage) backImage.style.visibility = 'visible'
    }, 1000) // Ajuste según el CSS
  }, 5000)
}

export { cargaInicialHeroMobile }
