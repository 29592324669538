class BlogPostHeader {
  constructor () {
    this.init()
  }

  init () {
    function copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        function () {
          console.log('Async: Copying to clipboard was successful!')
        },
        function (err) {
          console.error('Async: Could not copy text: ', err)
        }
      )
    }

    function fallbackCopyTextToClipboard (text) {
      const dummyField = document.createElement('input')
      dummyField.value = text

      document.body.appendChild(dummyField)
      dummyField.focus()
      dummyField.select()

      try {
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: Copying text command was ' + msg)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(dummyField)
    }

    const copyLinkButton = document.getElementById('copy-link')
    if (copyLinkButton) {
      copyLinkButton.addEventListener('click', () => {
        const currentUrl = window.location.href
        copyTextToClipboard(currentUrl)
      })
    }
  }
}

export default BlogPostHeader
