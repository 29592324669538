class Animations {
  constructor () {
    this.animatedModules = document.getElementsByClassName('content')
    if (this.animatedModules) {
      this.init()
    }
  }

  init () {
    Array.prototype.forEach.call(this.animatedModules, function (animatedModule) {
      const animationContainer = animatedModule.getElementsByClassName('content__img-area')[0]
      const animationPath = animationContainer ? animationContainer.getAttribute('data-animation-path') : null
      if (animationPath && animationPath !== undefined && animationPath !== null) {
        if (window.lottie === undefined) {
          window.loadJS('/wp-content/themes/kuady/js/vendor/lottie.js', () => {
            window.lottie.loadAnimation({
              container: animationContainer, // the dom element that will contain the animation
              renderer: 'svg',
              loop: true,
              autoplay: true,
              path: animationPath, // the path to the animation json
              rendererSettings: {
                scaleMode: 'noScale'
              }
            })
          })
        } else {
          setTimeout(() => {
            this.animate(this.animatedModule, animationPath)
          }, 1000)
        }
      }
    })
  }
}

export default Animations
