const tiltCDN = () => {
  if (!document.querySelector('script[src="https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.0/vanilla-tilt.min.js"]')) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/vanilla-tilt/1.7.0/vanilla-tilt.min.js'
    script.defer = true

    script.onload = () => {
      if (document.querySelector('[data-tilt]')) window.VanillaTilt.init(document.querySelectorAll('[data-tilt]'))
    }

    document.head.appendChild(script)
  } else {
    if (document.querySelector('[data-tilt]')) window.VanillaTilt.init(document.querySelectorAll('[data-tilt]'))
  }
}

export { tiltCDN }
