const cargaInicialHero = () => {
  let grados = 0

  const frontImage = document.querySelector('.front img')
  const backImage = document.querySelector('.back img')
  const flipper = document.querySelector('.flipper')

  // SOMBRA IMG

  if (backImage) backImage.style = 'filter: drop-shadow(10px 12px 30px rgba(68, 64, 64, 0.2))'
  if (frontImage) frontImage.style = 'filter: drop-shadow(10px 12px 30px rgba(68, 64, 64, 0.2))'

  let isFlipped = false
  let currentIndex = 0
  const mainImg = [...document.querySelectorAll('[name="hero_new"] .flipper input[type="hidden"]')].map((img) => img.value)

  // Establecer la primera imagen al inicio
  if (frontImage) frontImage.src = mainImg[0]
  if (backImage) backImage.src = mainImg[1] // Establecer la siguiente imagen

  if (window.customFlipperInterval) clearInterval(window.customFlipperInterval)

  window.customFlipperInterval = setInterval(() => {
    currentIndex++
    if (currentIndex > mainImg.length - 1) currentIndex = 0

    if (isFlipped) {
      grados = grados + 180
      // flipper.style.transform = 'rotateY(0deg)'
      if (flipper) flipper.style.transform = `rotateY(${grados}deg)`

      // currentIndex = (currentIndex + 1) % mainImg.length
      if (frontImage) frontImage.src = mainImg[currentIndex]
    } else {
      grados = grados + 180
      // flipper.style.transform = 'rotateY(180deg)'
      if (flipper) flipper.style.transform = `rotateY(${grados}deg)`

      // currentIndex = (currentIndex + 1) % mainImg.length
      if (backImage) backImage.src = mainImg[currentIndex]
    }
    isFlipped = !isFlipped

    // Mostrar las imágenes cuando haya terminado la transición
    setTimeout(() => {
      if (frontImage) frontImage.style.visibility = 'visible'
      if (backImage) backImage.style.visibility = 'visible'
    }, 1000) // Ajuste según el CSS
  }, 5000)

  // Rotación Hover

  if (document.querySelector('[name="hero_new"] > .hero__content')) {
    document.querySelector('[name="hero_new"] > .hero__content').addEventListener('mousemove', function (e) {
      const xAxis = -((window.innerWidth / 2 - e.pageX) / 50)
      const yAxis = (window.innerHeight / 2 - e.pageY) / 20
      if (document.querySelector('.card')) document.querySelector('.card').style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`
    })

    document.querySelector('[name="hero_new"] > .hero__content').addEventListener('mouseleave', function (e) {
      if (document.querySelector('.card')) document.querySelector('.card').style.transform = 'rotateY(0deg) rotateX(0deg)'
    })
  }

  document.addEventListener('scroll', () => {
    if (document.querySelector('.card')) document.querySelector('.card').style.transform = 'rotateY(0deg) rotateX(0deg)'
  })

  // PRUEBA DE ROBERT

  const imgLeft = () => {
    let divs = document.querySelectorAll('.card-1, .skuad-2, .money-3')
    let index = 0

    divs.forEach((div, i) => {
      if (i !== 0) div.style.display = 'none'
      else div.style.display = 'block'
    })

    if (window.customImgLeftInterval) clearInterval(window.customImgLeftInterval)

    window.customImgLeftInterval = setInterval(() => {
      divs = document.querySelectorAll('.card-1, .skuad-2, .money-3')

      if (divs.length > 0) {
        divs.forEach((div) => {
          div.style.opacity = 0
          div.style.transform = 'scale(0.8) rotateY(90deg)'
        })

        setTimeout(() => {
          divs[index].style.display = 'none'
          index = (index + 1) % divs.length
          divs[index].style.display = 'block'

          setTimeout(() => {
            divs[index].style.opacity = 1
            divs[index].style.transform = 'scale(1) rotateY(0deg) translateZ(25px)'
          }, 50) // Añadir un pequeño retraso para evitar que la transición sea instantánea
        }, 500) // Tiempo de transición
      }
    }, 5000) // Cambio cada 5 segundos
  }

  imgLeft()

  // IMG DERECHA

  const imgRight = () => {
    let divs = document.querySelectorAll('.ticket-1, .phone-card, .card-icons')
    let index = 0

    divs.forEach((div, i) => {
      if (i !== 0) div.style.display = 'none'
      else div.style.display = 'block'
    })

    if (window.customImgRightInterval) clearInterval(window.customImgRightInterval)

    window.customImgRightInterval = setInterval(() => {
      divs = document.querySelectorAll('.ticket-1, .phone-card, .card-icons')

      if (divs.length > 0) {
        divs.forEach((div) => {
          div.style.opacity = 0
          div.style.transform = 'scale(0.8) rotateY(90deg)'
        })

        setTimeout(() => {
          divs[index].style.display = 'none'
          index = (index + 1) % divs.length
          divs[index].style.display = 'block'

          setTimeout(() => {
            //  divs[index].style.opacity = 1
            // IF para conseguir que el ticket esté por debajo del wireframe
            if (divs[index].className === 'ticket-1') {
              divs[index].style.opacity = 1
              divs[index].style.transform = 'scale(1) rotateY(0deg) translateZ(-35px)'
            } else {
              divs[index].style.opacity = 1
              divs[index].style.transform = 'scale(1) rotateY(0deg) translateZ(25px)'
            }
          }, 50)
        }, 500)
      }
    }, 5000)
  }

  imgRight()
}

export { cargaInicialHero }
