import FsLightbox from 'fslightbox' // eslint-disable-line

class Product {
  constructor () {
    this.videoPlayButton = document.getElementsByClassName('hero__video-play')[0]
    if (this.videoPlayButton) {
      this.init()
    }
  }

  init () {
    refreshFsLightbox() // eslint-disable-line

    // Autoplay popup video
    fsLightboxInstances[''].props.onOpen = function (FsLightbox) { // eslint-disable-line
      function playWhenReady () {
        if (document.querySelectorAll('.fslightbox-container video')[0]) {
          document.querySelectorAll('.fslightbox-container video')[0].play()
        } else {
          setTimeout(playWhenReady, 500)
        }
        return true
      }
      return playWhenReady()
    }
  }
}

export default Product
