const modalesCDN = () => {
  // Script para el CDN
  if (!document.querySelector('script[src="https://cdn.jsdelivr.net/gh/loadingio/ldcover/dist/index.min.js"]')) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://cdn.jsdelivr.net/gh/loadingio/ldcover/dist/index.min.js'
    script.defer = true

    script.onload = () => {
      loadEvents()
    }

    document.head.appendChild(script)
  } else { loadEvents() }
}
function loadEvents () {
  try {
    if (document.querySelector('.btn-register-qr')) {
      const btnRegisterQR = new window.ldcover({ root: '.btn-register-qr' })

      // Crear el elemento div
      const divTrigger = document.querySelector('.modal-trigger')

      // Agregar dinámicamente el evento onclick que llama a btnRegisterQR.toggle()
      divTrigger.onclick = function () { btnRegisterQR.toggle() }
    }
  } catch (error) { }
}

export { modalesCDN }
